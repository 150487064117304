<template>
    <li
        class="vut-menu__nav-item--root"
        :class="{
            'vut-menu__nav-item--nl': noLeftBorder,
        }"
    >

        <MaybeWrappedInDiv>
            <BaseUiButton
                :to="category.getUrl() ?? ''"
                class="vut-menu__nav-item flex size-full items-center !px-7"
                :class="{
                    '!pr-0': hasSubmenu,
                    'text-secondary': isSubmenuOpen,
                    'text-secondary underline': isSomeChildActive,
                }"
                active-class="text-secondary underline"
                color="white"
                variant="sharp"
                @click="$emit('navigated')"
            >
                {{ category.name }}
            </BaseUiButton>

            <BaseUiButton
                v-if="hasSubmenu"
                color="white"
                variant="sharp"
                class="vut-menu__nav-item !px-6"
                :aria-label="isSubmenuOpen ? $t('accessibility.close_submenu_for', [category.name]) : $t('accessibility.open_submenu_for', [category.name])"
                :aria-expanded="isSubmenuOpen"
                :aria-controls="submenuId"
                @click="isSubmenuOpen = !isSubmenuOpen"
            >
                <IconChevron
                    class="text-secondary"
                    aria-hidden="true"
                    :size="14"
                    :rotate="isSubmenuOpen ? 'up' : 'down'"
                />
            </BaseUiButton>
        </MaybeWrappedInDiv>

        <BaseUtilCollapse v-model="isSubmenuOpen">
            <ul
                class="vut-ul-menu vut-menu__nav-submenu vut-border-right vut-border-bottom"
                :class="{
                    'vut-border-left': !noLeftBorder,
                }"
            >
                <li v-for="child in category.children" :key="child.id!">
                    <NuxtLink :to="child.getUrl() ?? ''" active-class="text-secondary underline" @click="$emit('navigated')">
                        {{ child.name }}
                    </NuxtLink>
                </li>
            </ul>
        </BaseUtilCollapse>
    </li>

</template>

<script lang="tsx" setup>
import { BaseUiButton } from '#components'
import type { FunctionalComponent } from 'vue'
// deliberately importing the vue-router version so that the behavior is the same as the one in NuxtLink
// (the route should be updated before suspense is resolved)
import { useRoute } from 'vue-router'

const {
    category,
    noLeftBorder,
} = defineProps<{
    category: InstanceType<typeof CategoryTreeModel>
    noLeftBorder: boolean
}>()

const hasSubmenu = !!category.children.length
const submenuId = hasSubmenu ? useId() : undefined
const isSubmenuOpen = ref<boolean>(false)

const MaybeWrappedInDiv: FunctionalComponent = (_, ctx) => {
    if (hasSubmenu) {
        return <div class="flex">
            {ctx.slots.default?.()}
        </div>
    }

    return ctx.slots.default?.()
}

const route = useRoute()
const isSomeChildActive = computed(() => {
    if (!hasSubmenu) {
        return false
    }

    return category.children.some((child) => {
        return route.path === child.getUrl()
    })
})
watch(isSomeChildActive, (val) => {
    if (val) isSubmenuOpen.value = true
}, { immediate: true })

const emit = defineEmits<{
    navigated: []
}>()

</script>

<style lang="scss" scoped>
.vut-menu__nav-item {
    display: flex;
    height: $vut-nav-item-height;

    border: hr-line() !important;   // TODO: remove !important when different style order in build is fixed

    &:hover {
        background-color: $vut-c-ivory;
        color: $vut-c-secondary;
    }
}

.vut-menu__nav-item--root .vut-menu__nav-item {
    border-top: none !important;
}

// do not add left border to the chevron button in popup mode (where noLeftBorder is false)
.vut-menu__nav-item + .vut-menu__nav-item {
    border-left: none !important;
}

.vut-menu__nav-item--root:first-child .vut-menu__nav-item {
    border-top: none !important;
}

.vut-menu__nav-item--nl .vut-menu__nav-item,
.vut-menu__nav-item--nl.vut-menu__nav-item {
    border-left: none !important;
}

.vut-menu__nav-submenu {
    padding: 1.25rem 1.75rem;
    background-color: #F5F5F5;
}

</style>
