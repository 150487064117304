<template>
    <div>
        <BaseUiButton
            v-if="collapsible"
            ref="collapseButton"
            color="secondary"
            variant="sharp"
            class="vut-menu__nav-item-button"
            align="between"
            @click="isExpanded = !isExpanded"
        >
            {{ $t('labels.show_catalog') }}

            <template #trailing>
                <IconChevron size="xs" class="text-blue" :rotate="isExpanded ? 'up' : 'down'" />
            </template>
        </BaseUiButton>

        <MaybeCollapseOrPopup>
            <nav
                :class="{
                    'size-full overflow-y-auto': asPopup,
                }"
                :aria-label="$t('accessibility.primary_navigation')"
            >
                <ul>
                    <MenuLink
                        v-for="category in categories"
                        :key="category.id!"
                        :category="category"
                        :no-left-border="!asPopup"
                        @navigated="() => {
                            if (asPopup) {
                                isExpanded = false
                            }
                        }"
                    />
                </ul>
            </nav>
        </MaybeCollapseOrPopup>
    </div>
</template>

<script lang="tsx" setup>
import type { FunctionalComponent } from 'vue'
import { BaseUtilCollapse, BaseUiPopup, type BaseUiButton } from '#components'

const {
    categories,
    collapsible,
    asPopup,
} = defineProps<{
    categories: InstanceType<typeof CategoryTreeModel>[]
    collapsible?: boolean
    /**
     * Whether to show the navigation in a popup.
     * Only makes sense if `collapsible` is `true`.
     */
    asPopup?: boolean
}>()

const isExpanded = ref<boolean>(false)
const collapseButton = useTemplateRef<ComponentInstance<typeof BaseUiButton>>('collapseButton')

const MaybeCollapseOrPopup: FunctionalComponent = (_, { slots }) => {
    if (collapsible) {
        if (asPopup) {
            return <BaseUiPopup
                v-model={isExpanded.value}
                reference={collapseButton.value}
                offset={0}
                safe-padding={0}
                match-min-reference-width
                render-method="display"
                class="vut-menu__popup"
                hover
                lock-scroll={false}
            >
                {slots.default?.()}
            </BaseUiPopup>
        }

        return <BaseUtilCollapse v-model={isExpanded.value}>
            {slots.default?.()}
        </BaseUtilCollapse>
    }

    return slots.default?.()
}

</script>

<style lang="scss" scoped>

.vut-menu__nav-item-button {
    padding-left: 1.75rem !important; /* TODO fix different style order during build */
    padding-right: 1.5rem !important;   // align with menu item chevron
    width: 100%;
    height: $vut-nav-item-height;
}

:deep(.vut-menu__popup) {
    box-shadow: $vut-shadow-lg;

    @include vut-popup-transitions;
}

</style>
